<div class="container-fluid p-0 d-flex flex-column h-100 justify-content-between nav sidebar-nav">
  <ul class="nav flex-column p-0">
    <div class="admin-sidebar-wrapper">
      <img style="width: 240px;padding: 20px; margin: 0px 12px;"
           src="assets/new_logo.png"
           alt="">
           <div style="padding: 0px 30px;color:#b9b9b9;">
            <p class="package-name">{{ package ? package?.package?.name : 'Free Package' }}</p>
            <p *ngIf="package" class="package-name">Expiry date {{ package?.expiry_datetime | date:shortDate }}</p>
           </div>
      <div style="height: calc(90vh - 20px);"
           malihu-scrollbar
           [scrollbarOptions]="scrollbarOptions"
           *ngIf="showSideBar">
        <li  class="nav-item"
            *ngFor="let items of menus">
          <a *ngIf="items.visible" routerLinkActive="active" [routerLink]="branch_id ? items.routerLink :'branches/select'" class="nav-link" 
             role="button"
          
            >
            <div fxLayout="row"
                 fxLayoutAlign="space-around center">
              <span class="material-icons">{{items.logo}}</span>
              <span class="mx-2"
                    style="font-size: 18px;"
                    translate="{{items.label}}"></span>
            </div>
          </a>
        </li>
        <div class="row mt-4">
          <div class="col-sm-11"
               [ngClass]="{'wallet-container': certificates > 0, 'wallet-container-red' : certificates <1 }">
            <div class="container-fluid">
              <div class="row flex-reverse-rtl">
                <div class="col-sm-8 p-0">
                  <!-- ng-style="{'color':certificates == 0 ?  red: pink}" -->
                  <h1 class="text-primary m-0">{{certificates}}</h1>
                  <p class="small m-0"
                     translate="statsBar.currentCertBalance"></p>
                  <button *ngIf="getRechargeCondition()" class="btn btn-default btn-border-radius bg-white shadow-grey mt-3"
                          routerLink="/package"
                          translate="statsBar.recharge"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
       <div class="row" style="margin-top: 48px; margin-bottom: 21px;" *ngIf="(ShahadhUser?.user?.role == 'supervisor') || (ShahadhUser?.user?.role == 'employee')">
<div class="col-3">
   <img src="assets/images/user.jfif" alt="" style="width: 53px;height: 52px;border-radius: 50%;">
</div>
<div class="col-9">
<h2 style="font-size:15px">
  {{ShahadhUser?.user?.name}}
 
</h2>
<span><a routerLink="/user/profile" style="color:#909aa7"  translate="sideNav.switch"></a></span>
</div>
       </div>
        <ul class="nav flex-column p-0">
          <li class="nav-item my-0">
            <a class="nav-link"
               for="orgLangSelector">
              <span class="lnr lnr-keyboard h4"></span>
             
              <sha-lang-selector id="orgLangSelector"></sha-lang-selector>
            </a>
          </li>
          <li class="nav-item my-0">
            <a class="nav-link text-danger"
               role="button"
               (click)="logout()">
              <span class="lnr lnr-power-switch"></span>
              <span class="mx-2"
                    translate="sideNav.logout"></span>
            </a>
          </li>
          <li class="nav-item my-0">
            <a class="nav-link text-muted"
               translate="sideNav.copyright"></a>
          </li>
        </ul>
      </div>
      <div *ngIf="!showSideBar"
           class="spinner chart-height"
           style="display: flex; justify-content: center; align-items: center; background: white; margin-top: 50%">
        <mat-progress-spinner [diameter]="70" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>

  </ul>
</div>
